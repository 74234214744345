





















import breakpoints from 'library/assets/vars/breakpoints'

export default

	props:
		block: Object

	data: ->
		config:
			slidesPerPage: 3
			showDots: false
			showArrows: true
			loop: true
			gutter: 'var(--card-gutter)'
			responsive: [
				{
					maxWidth: parseInt breakpoints.tablet
					slidesPerPage: 2
				},
				{
					maxWidth: parseInt breakpoints.mobile
					slidesPerPage: 1
				}
			]

