




























import { mergeShopifyProductCard } from 'library/helpers/products'
import { mountComponent } from 'library/helpers/dom'
import UGCModal from 'library/components/globals/modals/ugc'

export default
	props: card: Object

	data: ->
		fullProduct: {}
		loaded: false

	mounted: ->
		@fullProduct = await mergeShopifyProductCard @card.product[0]
		@loaded = true


	methods:
		onCardClick: (e) ->
			await mountComponent UGCModal,
				parent: this
				propsData: {
					video: @card?.video?[0]
					product: @fullProduct
				}

