

















import QuickShopContent from '~/components/globals/product/quick-shop/content'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'

export default

	components: { QuickShopContent }

	props:
		video: Object
		product: Object

	data: -> loaded: false

	mounted: -> @loaded = true

	methods:

		handleDestroy: ->
			@$destroy()
			@$el.remove()

