import { render, staticRenderFns } from "./ugc-videos.vue?vue&type=template&id=dc295fa8&scoped=true&lang=pug&"
import script from "./ugc-videos.vue?vue&type=script&lang=coffee&"
export * from "./ugc-videos.vue?vue&type=script&lang=coffee&"
import style0 from "./ugc-videos.vue?vue&type=style&index=0&id=dc295fa8&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc295fa8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardsUgc: require('/opt/build/repo/nuxt-app/components/globals/cards/ugc.vue').default,SsrCarousel: require('/opt/build/repo/node_modules/vue-ssr-carousel/index.js').default})
